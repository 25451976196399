import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Silhouette Clustering`}</h1>
    <p>{`The silhoette coefficient is a method for analyzing how well a particular partitioning (or `}<a parentName="p" {...{
        "href": "/clustering",
        "title": "clustering"
      }}>{`clustering`}</a>{`) of the data represents the full distribution.
This method is frequently used to select for the number of clusters (`}<inlineCode parentName="p">{`K`}</inlineCode>{`) in a `}<a parentName="p" {...{
        "href": "/K-means",
        "title": "K means"
      }}>{`K means`}</a>{` `}<a parentName="p" {...{
        "href": "/clustering",
        "title": "clustering"
      }}>{`clustering`}</a>{` analysis.`}</p>
    <ul>
      <li parentName="ul">{`See wikipedia for more: `}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Silhouette_(clustering)"
        }}>{`https://en.wikipedia.org/wiki/Silhouette_(clustering)`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      